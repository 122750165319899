<template>
  <div class="app-container">
    <CrudTable
      entity="LocalProduct"
      :columns="columns"
      :generate-summary="generateSummary"
      :permission-code="'LocalProduct'"
      :default-filters="{ onlineYN: 1 }"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter, priceFormatter, formatOption } from '@/utils/filters';

export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        { field: 'name' },
        {
          field: 'restaurantName',
          label: 'common.restaurantName'
        },
        {
          field: 'localProductCategoryName',
          label: 'localProduct.localProductCategory'
        },
        { field: 'price', formatter: priceFormatter },
        {
          field: 'vatRateOption',
          label: 'common.vatRateOption',
          minWidth: '100px',
          sortable: false,
          formatter: formatOption(this, 'vatRate'),
          filter: {
            type: 'select',
            options: this.$store.getters['option/loadedOptions']('vatRate'),
            valueField: 'code',
            labelField: 'description'
          }
        },
        { field: 'ean' },
        { field: 'deposit', label: 'product.deposit', formatter: priceFormatter },
        {
          field: 'onlineYN',
          label: 'common.onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        },
        { field: 'position', label: 'common.positionInCashDesk' }
      ]
    };
  },
  methods: {
    generateSummary({ name, restaurantName }) {
      return `${name} (${restaurantName})`;
    }
  }
};
</script>

<style scoped></style>
